var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "userLoginStatis", staticClass: "user-login-statis" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.clickTab },
          model: {
            value: _vm.form.type,
            callback: function($$v) {
              _vm.$set(_vm.form, "type", $$v)
            },
            expression: "form.type"
          }
        },
        [
          _c("el-tab-pane", { attrs: { label: "统计", name: "1" } }, [
            _c(
              "div",
              { ref: "form", staticClass: "form-area" },
              [
                _c(
                  "analysis-form",
                  {
                    attrs: { showCar: false, requireCompany: true },
                    on: { getFormVal: _vm.onSubmit }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "small",
                          disabled: _vm.comArr.length === 0,
                          loading: _vm.downLoadStatus
                        },
                        on: {
                          click: function($event) {
                            return _vm.exportData(1)
                          }
                        }
                      },
                      [_vm._v("导出")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.comArr.length > 0
              ? _c(
                  "div",
                  { staticClass: "form-table" },
                  [
                    _c(
                      "el-table",
                      {
                        staticClass: "left",
                        attrs: {
                          data: _vm.comArr,
                          height: _vm.tableHeight,
                          stripe: ""
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "index", label: "序号" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "companyName",
                            label: "所属企业",
                            "show-overflow-tooltip": true,
                            width: "200px"
                          }
                        }),
                        _vm._l(_vm.comArr[0].detailList, function(item) {
                          return _c(
                            "el-table-column",
                            {
                              key: item.statisticsDay,
                              attrs: {
                                label: item.statisticsDay,
                                "show-overflow-tooltip": true,
                                width:
                                  _vm.comArr[0].detailList.length > 10
                                    ? "130"
                                    : ""
                              }
                            },
                            [[_vm._v(_vm._s(item.signDuration))]],
                            2
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "count",
                            label: "总在线时长（小时）",
                            "show-overflow-tooltip": true,
                            width: "150px"
                          }
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "el-tab-pane",
            { attrs: { label: "详情", name: "2" } },
            [
              _c(
                "div",
                { ref: "form1", staticClass: "form-area" },
                [
                  _c(
                    "analysis-form",
                    {
                      attrs: {
                        showCar: false,
                        showUserName: true,
                        requireCompany: true
                      },
                      on: { getFormVal: _vm.onSubmit }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            disabled: _vm.carArr.length === 0,
                            loading: _vm.downLoadStatus
                          },
                          on: {
                            click: function($event) {
                              return _vm.exportData(2)
                            }
                          }
                        },
                        [_vm._v("导出")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("car-table", {
                ref: "tableDetail",
                attrs: {
                  height: _vm.tableHeight,
                  total: _vm.total,
                  tableData: _vm.carArr
                },
                on: { sendInfo: _vm.getPageInfo }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }